define("discourse/plugins/History/connectors/user-custom-preferences/protect-history", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="controls">
    <label class="checkbox-label">
      {{input
        type="checkbox"
        checked=this.controller.model.custom_fields.protect_history
      }}
      Don't update URL while scrolling through topics
    </label>
  </div>
  */
  {
    "id": "saWOSDJL",
    "block": "[[[10,0],[14,0,\"controls\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"checkbox-label\"],[12],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"type\",\"checked\"],[\"checkbox\",[30,0,[\"controller\",\"model\",\"custom_fields\",\"protect_history\"]]]]]],[1,\"\\n    Don't update URL while scrolling through topics\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"div\",\"label\",\"input\"]]",
    "moduleName": "discourse/plugins/History/connectors/user-custom-preferences/protect-history.hbs",
    "isStrictMode": false
  });
});